<template>
  <!--	根据路由参数加载对应的子系统-->

  <component :is="system_key" class="h-100 w-100"></component>
</template>

<script>
import {onMounted, reactive, toRefs, watch} from "@vue/composition-api";
import {routerParams} from "@/libs/utils/routerParams";
import store from "@/store";

export default {
  name: "SubSystems",
  components: {
    airConditioningVentilation: () => import("./components/air-conditioning-ventilation"),
    energyManagement: () => import("./components/energy-management"),
    coldHeatSource: () => import("./components/cold-heat-source"),
    electricalSubstation: () => import("./components/electrical-substation"),
    intelligentElevator: () => import("./components/intelligent-elevator"),
    floodlighting: () => import("./components/floodlighting"),
    intelligentEnvironment: () => import("./components/intelligent-environment"),
    intelligentFireFighting: () => import("./components/intelligent-fire-fighting"),
    intelligentLighting: () => import("./components/intelligent-lighting"),
    intelligentSecurity: () => import("./components/intelligent-security"),
    waterAlarm: () => import("./components/water-alarm"),
    emergencyEvacuation: () => import("./components/emergency-evacuation"),
    waterSupplyDrainage: () => import("./components/water-supply-drainage"),
    parking: () => import("./components/parking"),
    hiddenConstructionWork: () => import("./components/hidden-construction-work"),
  },
  setup() {
    const event = reactive({
      // system_key: computed(() => {
      // 	return routerParams('system_key');
      // }),
      system_key: routerParams("system_key"),
    });
    onMounted(() => {
      // store.state.jsf.theme
      //   ? document.body.setAttribute("data-theme", store.state.jsf.theme)
      //   : localStorage.getItem("theme")
      //   ? document.body.setAttribute(
      //       "data-theme",
      //       localStorage.getItem("theme")
      //     )
      //   : document.body.setAttribute("data-theme", "");
    });
    watch(
        () => store.state.menus.current.params.system_key,
        (val) => {
          event.system_key = val;
        }
    );
    return {
      ...toRefs(event),
    };
  },
};
// window.onload = () => {
//   if (document.documentElement.clientWidth <= 1280) {
//     document.documentElement.style.fontSize = 12 + "px";
//   } else {
//     document.documentElement.style.fontSize =
//       12 + (document.documentElement.clientWidth - 1280) * 0.0026 + "px";
//   }
// };
// window.onresize = () => {
//   if (document.documentElement.clientWidth <= 1280) {
//     document.documentElement.style.fontSize = 12 + "px";
//   } else {
//     document.documentElement.style.fontSize =
//       12 + (document.documentElement.clientWidth - 1280) * 0.0026 + "px";
//   }
// };
</script>

<style lang="scss" scoped>
/*  div ::v-deep .list-group .list-group-item{
border-bottom: 0;
&:hover{
background-color: rgb(190,224,255) !important;
}
} */
</style>
